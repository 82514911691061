import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
// import ReactDOM from "react-dom";
import { extendTheme, ChakraProvider } from "@chakra-ui/react";
import "./index.css";
// import reportWebVitals from "./reportWebVitals";
// import App from "./App";
const App = React.lazy(() => import("./App"));

const theme = extendTheme({
  config: {
    initialColorMode: "dark",
  },
});

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <Suspense
        fallback={
          <div>
            <div className="loading-topbar mobile-only">Welcome!</div>
            <div className="loading-topbar desktop-only">
              Welcome to CrissWord
            </div>

            <div className="loading-body">
              <p>
                CrissWord is essentially a{" "}
                <strong>2-clue mini crossword</strong> where there are multiple
                common letters in the answers. Clues can either be synonyms of
                the answer or wordplay, sometimes based on general knowledge.
              </p>

              <p
                style={{
                  marginTop: 24,
                }}
              >
                There are <strong>new puzzles every day</strong> but if you want
                to try more, use the drop-down menu in the top right to view the
                last 5 days.
              </p>

              <p
                style={{
                  marginTop: 24,
                }}
              >
                Have a read through the tutorial, then start playing and sharing
                your results.
              </p>
            </div>
          </div>
        }
      >
        <App />
      </Suspense>
    </ChakraProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
